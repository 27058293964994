import React, { Update } from 'history';
import { ReactElement, useState, useLayoutEffect, ReactNode } from 'react';
import { Router } from 'react-router';

import sharedHistory from '^routing/sharedHistory';

export interface ICustomRouterProps {
  children: ReactNode;
}

const CustomRouter = ({ children }: ICustomRouterProps): ReactElement => {
  const [state, setState] = useState<Update>(sharedHistory);

  useLayoutEffect(
    (): (() => void) => sharedHistory.listen(setState),
    [],
  );

  return (
    <Router
      children={children}
      navigator={sharedHistory}
      {...state}
    />
  );
};

export default CustomRouter;
