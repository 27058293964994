import React, { ReactElement } from 'react';
import { GlobalConfig } from '@interdan/admin-ui';
import AuthProvider, { authService } from '@interdan/auth-provider/dist';

import authConfig from '^configs/authConfig';
import CustomRouter from '^components/CustomRouter';
import ErrorBoundary from '^components/ErrorBoundary';
import RoutingRules from '^routing/RoutingRules';

const App = (): ReactElement => (
  <AuthProvider
    authConfig={authConfig}
    minimalPermissions={[]}
    onUnauthorized={authService.logout}
  >
    <ErrorBoundary>
      <GlobalConfig>
        <CustomRouter>
          <RoutingRules />
        </CustomRouter>
      </GlobalConfig>
    </ErrorBoundary>
  </AuthProvider>
);

export default App;
