import RouteUrl from '^routing/routes/routeUrl';
import sharedHistory from '^routing/sharedHistory';

export const getDefaultRoutePath = (): string => (
  RouteUrl.Default
);

export const goToDefaultRoute = (): void => {
  sharedHistory.push(getDefaultRoutePath());
};
