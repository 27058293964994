import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

import { APP_INSIGHTS_INSTRUMENTATION_KEY } from '^configs/environments';
import IAppInsightsCustomProperties from '^types/common/IAppInsightsCustomProperties';

class Logger {
  private appInsights: ApplicationInsights | null = null;

  private trackValue = (value: any, properties: IAppInsightsCustomProperties, severityLevel: SeverityLevel): void => {
    if (this.appInsights) {
      properties.route = window.location.href;

      if (severityLevel === SeverityLevel.Critical || severityLevel === SeverityLevel.Error) {
        this.appInsights.trackException({
          properties,
          severityLevel,
          error: value,
        });

        return;
      }

      this.appInsights.trackTrace({
        properties,
        severityLevel,
        message: value,
      });

      return;
    }

    switch (severityLevel) {
      case SeverityLevel.Critical:
      case SeverityLevel.Error:
        console.error(value);
        break;

      case SeverityLevel.Warning:
        console.warn(value);
        break;

      default:
        console.info(value);
        break;
    }
  };

  public init = (): void => {
    if (this.appInsights || !APP_INSIGHTS_INSTRUMENTATION_KEY) {
      return;
    }

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: APP_INSIGHTS_INSTRUMENTATION_KEY,
      },
    });

    this.appInsights.loadAppInsights();
  };

  public trackError = (error: Error, properties: IAppInsightsCustomProperties): void => {
    this.trackValue(error, properties, SeverityLevel.Error);
  };

  public trackCriticalError = (error: Error, properties: IAppInsightsCustomProperties): void => {
    this.trackValue(error, properties, SeverityLevel.Critical);
  };

  public trackInfo = (message: string, properties: IAppInsightsCustomProperties): void => {
    this.trackValue(message, properties, SeverityLevel.Information);
  };

  public trackWarning = (message: string, properties: IAppInsightsCustomProperties): void => {
    this.trackValue(message, properties, SeverityLevel.Warning);
  };
}

export default new Logger();
