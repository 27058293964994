import React, { ReactElement } from 'react';
import { SvgIconProps } from '@material-ui/core';

const PlaceholderIcon = (props: SvgIconProps): ReactElement => (
  <svg
    width="184"
    height="117"
    viewBox="0 0 184 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M92 117C142.81 117 184 108.057 184 97.0244C184 85.9922 142.81 77.0488 92 77.0488C41.1898 77.0488 0 85.9922 0 97.0244C0 108.057 41.1898 117 92 117Z"
      fill="#DADEE6"
    />

    <rect
      x="34"
      y="6"
      width="116"
      height="85"
      rx="5"
      fill="#F5F7FA"
      stroke="#DADEE6"
    />

    <path
      d="M34 11C34 8.23858 36.2386 6 39 6H145C147.761 6 150 8.23858 150 11V22H34V11Z"
      fill="white"
    />

    <path
      d="M42.5 14.5H44M46.5 14.5H48M50.5 14.5H52M60.5 14.5H92M150 22V11C150 8.23858 147.761 6 145 6H39C36.2386 6 34 8.23858 34 11V22H150Z"
      stroke="#DADEE6"
      strokeLinecap="round"
    />

    <path
      d="M105.74 43.9355C107.691 40.7583 112.309 40.7583 114.26 43.9355L148.321 99.3829C150.367 102.714 147.97 107 144.061 107H75.9395C72.0296 107 69.6326 102.714 71.6791 99.3829L105.74 43.9355Z"
      fill="white"
      stroke="#DADEE6"
    />

    <circle
      cx="110"
      cy="94"
      r="3"
      fill="white"
      stroke="#DADEE6"
    />

    <path
      d="M106.379 68.982C106.176 66.8467 107.855 65 110 65C112.145 65 113.824 66.8467 113.621 68.982L112.19 84.009C112.082 85.1379 111.134 86 110 86C108.866 86 107.918 85.1379 107.81 84.009L106.379 68.982Z"
      fill="white"
      stroke="#DADEE6"
    />
  </svg>

);

export default PlaceholderIcon;
