import React, { StrictMode } from 'react';
import { render } from 'react-dom';

import logger from '^utils/logger';

import App from './App';

logger.init();

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
