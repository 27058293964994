const texts = {
  navigationMenu: {
    logOut: 'Log ud',
  },
  page: {
    main: {
      title: 'Oversigt',
    },
    error: {
      title: 'Noget gik galt',
      description: 'Prøv igen senere',
    },
  },
};

export default texts;
