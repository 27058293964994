import React, { ReactElement } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const Logo = (props: SvgIconProps): ReactElement => (
  <SvgIcon {...props}>
    <path d="M1.99569 8V16.2784H0V8H1.99569ZM6.93659 8L3.72984 12.1961L1.85355 14.2486L1.48966 12.4178L2.68935 10.5984L4.47467 8H6.93659ZM4.57701 16.2784L2.43918 12.5486L3.99138 11.4058L6.93659 16.2784H4.57701Z" />

    <path d="M9.37035 15.5279L11.0192 8H12.0028L12.5373 8.64249L10.7804 16.2784H9.69444L9.37035 15.5279ZM8.93255 8L10.3256 15.5848L9.96735 16.2784H8.71081L6.95392 8H8.93255ZM13.4413 15.545L14.823 8H16.7959L15.039 16.2784H13.7882L13.4413 15.545ZM12.7136 8L14.4022 15.5734L14.0611 16.2784H12.9751L11.1784 8.63112L11.7356 8H12.7136Z" />

    <path d="M20.9695 12.759H18.8374L18.826 11.4512H20.5544C20.8653 11.4512 21.1135 11.4171 21.2993 11.3489C21.485 11.2769 21.6196 11.1726 21.703 11.0362C21.7901 10.8997 21.8337 10.7291 21.8337 10.5245C21.8337 10.2895 21.7901 10.0999 21.703 9.95589C21.6158 9.81185 21.4774 9.70761 21.2879 9.64318C21.1022 9.57495 20.8615 9.54083 20.5658 9.54083H19.5879V16.2784H17.5922V8H20.5658C21.0775 8 21.5343 8.04738 21.9361 8.14214C22.3379 8.23311 22.679 8.37336 22.9595 8.56289C23.2438 8.75241 23.4599 8.99121 23.6077 9.27929C23.7555 9.56357 23.8294 9.89903 23.8294 10.2857C23.8294 10.623 23.7574 10.9395 23.6134 11.2352C23.4693 11.5308 23.2286 11.7715 22.8913 11.9573C22.5577 12.1392 22.101 12.234 21.521 12.2416L20.9695 12.759ZM20.8899 16.2784H18.3541L19.0364 14.7433H20.8899C21.1628 14.7433 21.3808 14.7016 21.5438 14.6182C21.7105 14.531 21.8299 14.4173 21.902 14.277C21.9778 14.133 22.0157 13.9738 22.0157 13.7994C22.0157 13.5872 21.9797 13.4033 21.9077 13.2479C21.8394 13.0925 21.7295 12.9731 21.5779 12.8897C21.4263 12.8025 21.2235 12.759 20.9695 12.759H19.2922L19.3036 11.4512H21.3107L21.7769 11.9743C22.3303 11.9516 22.7681 12.0293 23.0903 12.2074C23.4163 12.3856 23.6494 12.6206 23.7896 12.9125C23.9299 13.2043 24 13.5095 24 13.8279C24 14.3699 23.8825 14.8229 23.6475 15.1868C23.4163 15.5506 23.0694 15.8236 22.607 16.0055C22.1446 16.1874 21.5722 16.2784 20.8899 16.2784Z" />
  </SvgIcon>
);

export default Logo;
