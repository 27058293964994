import React, { ReactElement } from 'react';
import { BaseLayout } from '@interdan/admin-ui';
import { useRoutes, Navigate } from 'react-router-dom';

import { getDefaultRoutePath, getErrorRoutePath } from '^routing/routes';
import MainPage from '^pages/MainPage';
import ErrorPage from '^pages/ErrorPage';

import useNavigationMenuProps from '../hooks/useNavigationMenuProps';

const RoutingRules = (): ReactElement | null => {
  const navigationMenuProps = useNavigationMenuProps();

  const routes = useRoutes([
    {
      path: getDefaultRoutePath(),
      element: <MainPage />,
    },
    {
      path: getErrorRoutePath(),
      element: <ErrorPage />,
    },
    {
      path: '*',
      element: <Navigate to={getDefaultRoutePath()} />,
    },
  ]);

  return (
    <BaseLayout navigationMenuProps={navigationMenuProps}>
      {routes}
    </BaseLayout>
  );
};

export default RoutingRules;
