import React, { ReactElement, useEffect } from 'react';
import { PageHeader } from '@interdan/admin-ui';

import texts from '^configs/texts';
import { WIDGET_CONNECTION_URL } from '^configs/environments';

import styles from './styles.module.scss';

const WIDGET_CONTAINER_ID = 'kwb-portal-public-container';

const MainPage = (): ReactElement => {
  useEffect(
    (): void | (() => void) => {
      const script = document.createElement('script');

      script.src = WIDGET_CONNECTION_URL;

      document.body.appendChild(script);

      script.onload = (): void => {
        window.renderKWBPortalWidget?.({
          containerSelector: `#${WIDGET_CONTAINER_ID}`,
          mode: 'base',
        });
      };

      return (): void => {
        document.body.removeChild(script);
      };
    },
    [],
  );

  return (
    <>
      <PageHeader title={texts.page.main.title} />

      <div
        id={WIDGET_CONTAINER_ID}
        className={styles.widgetContainer}
      />
    </>
  );
};

export default MainPage;
