import React, { ReactElement } from 'react';
import { Placeholder } from '@interdan/admin-ui';

import texts from '^configs/texts';
import PlaceholderIcon from '^icons/PlaceholderIcon';

import styles from './styles.module.scss';

const ErrorPage = (): ReactElement => (
  <Placeholder
    Icon={PlaceholderIcon}
    className={styles.root}
    title={texts.page.error.title}
    subTitle={texts.page.error.description}
  />
);

export default ErrorPage;
