import { INavigationMenuItem, INavigationMenuProps, TNavigationMenuItem } from '@interdan/admin-ui';
import { authService } from '@interdan/auth-provider';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { useCallback, useMemo } from 'react';
import { Home } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';

import RouteUrl from '^routing/routes/routeUrl';
import texts from '^configs/texts';
import { goToDefaultRoute } from '^routing/routes';
import Logo from '^icons/Logo';

const useNavigationMenuProps = (): INavigationMenuProps => {
  const { pathname } = useLocation();

  const getItemSelected = useCallback(
    (item: INavigationMenuItem): boolean => pathname === item.value || (new RegExp(`^${item.value}/.+`).test(pathname)),
    [pathname],
  );

  const secondaryItems = useMemo(
    (): TNavigationMenuItem[] => [
      {
        value: RouteUrl.AuthCallback,
        label: texts.navigationMenu.logOut,
        Icon: ExitToApp,
        onClick: authService.logout,
      },
    ],
    [],
  );

  const items = useMemo(
    (): TNavigationMenuItem[] => [
      {
        value: RouteUrl.Default,
        label: texts.page.main.title,
        Icon: Home,
        onClick: goToDefaultRoute,
      },
    ],
    [],
  );

  return {
    secondaryItems,
    items,
    getItemSelected,
    Icon: Logo,
    onIconClick: goToDefaultRoute,
  };
};

export default useNavigationMenuProps;
